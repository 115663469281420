module.exports = {
  title: `Find Truth`,
  description: `A topical blog of Biblical studies from a brother in Christ`,
  author: `KP`,
  introduction: `In an age of confusion regarding Biblical truth, I seek to share my understanding of certain topics, according to what I have received. All views are my own, and do not represent any other group, organization or entity.`,
  siteUrl: `https://findtruth.online`,
  social: {
    twitter: ``, // Your Twitter account
    github: ``, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`],
  comment: {
    disqusShortName: 'findtruth-online',
    utterances: '',
  },
  configs: {
    countOfInitialPost: 10, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '', // intheam
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
}
